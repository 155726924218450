<template>
  <div>
    <places-component
      class="m-0"
      :placeholder="placeholder"
      :searchCountries="countries"
      :checkCountries="checkCountries"
      :bounds="bounds"
      ref="places"
      :fulladdress="fulladdress"
      :searchTypes="searchTypes"
      :show-label="false"
      @change-address="updateAddress"
      @change-invalid-value="updateInvalidValue"
      @change-invalid-country="updateInvalidCountry"
      @change-search-error="updateSearchErrorValue"
      @auto-complete-service-ready="emitAutoCompleteServiceReady"
    />
    <div
      v-if="invalid || searchError"
      class="text-danger py-1"
    >{{ locationErrorText }}</div>
  </div>
</template>

<script>
import inputMixin from '@/mixins/ui/inputMixin';
import placesComponent from '@/components/placesComponent.vue';
import LogService from '@/services/LogService';

export default {
  mixins: [inputMixin],
  components: {
    placesComponent,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        country: '',
      }),
    },
    locationErrorText: {
      type: String,
      default: () => (''),
    },
    checkCountries: {
      type: Boolean,
      default: () => (true),
    },
    hideDetails: {
      type: Boolean,
      default: () => false,
    },
    countries: {
      type: Array,
      default: () => [],
    },
    bounds: {
      type: Object,
      default: () => undefined,
    },
    fulladdress: {
      type: Boolean,
      default: false,
    },
    searchTypes: {
      type: Array,
      default: () => [],
    },
    /** required address component for validation */
    requiredComponents: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    propsNoValue() {
      const props = { ...this.$props };
      delete props.value;
      return props;
    },
  },
  data() {
    return {
      invalid: false,
      searchError: false,
    };
  },
  methods: {
    updateInvalidValue(invalidValue) {
      LogService.log('inputLocation component, update invalid value', invalidValue);
      this.invalid = invalidValue;
      this.$emit('change-invalid-value', invalidValue);
    },
    updateInvalidCountry(invalidCountry) {
      LogService.log('inputLocation component, update invalid country', invalidCountry);
      this.$emit('change-invalid-country', invalidCountry);
    },
    updateAddress(address) {
      LogService.log('inputLocation component, update address', address);
      this.$emit('change-address', address);
    },
    emitAutoCompleteServiceReady() {
      LogService.log('inputLocation component, emitAutoCompleteServiceReady');
      this.$emit('auto-complete-service-ready');
    },
    updateSearchErrorValue(searchErrorValue) {
      this.searchError = searchErrorValue;
    },
  },
};
</script>

<style lang="scss" scoped>
  ul.input-errors.empty-details {
    min-height: 1.5rem;
  }
  .input-label {
    padding-left: 1rem;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
  @media (max-width: 768px) {
    .input-label {
      font-size: .75rem;
      padding-bottom: 0.25rem;
    }
  }
</style>
