<template>
  <div
    class="wet-section wet-section-vacation d-flex"
    :class="{
      'wet-full-height': !onlyForm,
      'py-0': onlyForm,
    }"
    :style="{
      backgroundImage: !onlyForm ? backgroundImage : '',
    }"
  >
    <div
      :class="{
        'position-relative': true,
        'w-100': onlyForm,
      }"
      style="display: flex; margin: auto;"
    >
      <div
        class="d-flex flex-column justify-content-center"
        style="height: auto"
      >
        <div v-if="!onlyForm" class="wet-section-title" style="text-align: center">
          <h1 class="empty-ph text-white">{{ str.vacation.section_title }}</h1>
        </div>
        <div
          class="wet-section-box-rounded pb-1 mt-2"
          style="margin: auto"
          :style="{
            'max-width': $route.query.iframe !== '2' ? '35rem' : null,
          }"
        >
          <div class="font-weight-bold text-white"
               style="text-align: center" v-html=offerText>
          </div>
          <img v-if="partnerLogoImage != ''" :src="partnerLogoImage"
               style="width: 50%; margin: auto; display: block">
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3 mb-0">
              <input-location
                ref="location"
                :label="str.vacation.location_label"
                :placeholder="str.vacation.location_placeholder"
                :countries="insuranceCountries"
                :bounds="boundsEurope"
                :hide-details=true
                :fulladdress="false"
                dark
                labeled
                :location-error-text="str.vacation.error_location"
                :requiredComponents="['city']"
                :errors="errors"
                :searchTypes="searchTypes"
                :checkCountries="false"
                @change-address="updateAddress"
                @auto-complete-service-ready="setAutoFillServiceToReady"
              />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3">
              <period-alt
                :startDate.sync="formData.startDate"
                :endDate.sync="formData.endDate"
                :startText="str.vacation.start_date_label"
                :endText="str.vacation.end_date_label"
                :defaultStartDate="defaultStartDate"
                :defaultEndDate="defaultEndDate"
                :validationRules="validationRules"
                :calendLang="lang"
                :dates.sync="formData.dates"
                :disabled-start-date-message="firstDateInfo"
                dark
                labeled
              />
            </div>
          </div>
          <div class="row align-items-end mb-3">
            <div class="col-12 col-xl mt-3">
              <button class="wet-button wet-button-xs-full"
                      style="width: 100%"  @click="onNextClick">
                {{ str.vacation.btn_label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import hotelStyleLandingPage from '@/mixins/hotelStyleLandingPage';
import changeLang from '@/mixins/changeLang';
import periodAlt from '@/components/sections/periodAlt.vue';
import inputLocation from '@/components/ui/inputLocationUi.vue';
import places from '@/mixins/places';
import partnerCodeMixin from '@/mixins/partner-code';
import LogService from '@/services/LogService';
import wpSection from '@/enums/wpSection';
import { vacation as vacationStr, commonApp, partnerDesign } from '@/enums/stringIndices';
import { nanoid } from 'nanoid';
import { getVStemp } from '@/common/vStemp';
import setLayout from '@/mixins/setLayout';

export default {
  mixins: [common, places, partnerCodeMixin, hotelStyleLandingPage, changeLang, setLayout],
  components: {
    inputLocation,
    periodAlt,
  },
  data() {
    return {
      vStemp: undefined,
      widthBelow576px: false,
      businessType: 'vacation',
      campaignId: null,
      userStatus: '5',
      startDate: new Date(),
      endDate: new Date(),
      defaultStartDate: null,
      defaultEndDate: null,
      current: '',
      crops: [],
      formData: {},
      invalidCountry: false,
      searchTypes: [],
      screenWidth: 0,
      insuranceCountries: [],
      str: {
        vacation: vacationStr,
        commonApp,
        partnerDesign,
      },
      countries: ['DE'],
    };
  },
  computed: {
    partnerLogoImage() {
      const partnerDesignInfo = this.getPartnerDesign(this.partnerCode, this.str.partnerDesign);
      return this.getVacationPartnerLogo(
        this.partnerCode,
        partnerDesignInfo,
      );
    },
    firstDateInfo() {
      const min_contract_start_postponement_less_29 = this.$store.state.settings.calendar?.vacation
        ?.min_contract_start_postponement_less_29;
      const postpone = min_contract_start_postponement_less_29
        ? (min_contract_start_postponement_less_29 + 1).toString()
        : '';
      const date = this.$moment.today().add(min_contract_start_postponement_less_29 + 1, 'days')
        .toDate().toLocaleDateString(this.lang);
      return this.format(
        this.str.vacation.first_date_info,
        date,
        postpone,
      );
    },
    wpSizes() {
      const partnerDesignInfo = this.getPartnerDesign(this.partnerCode, this.str.partnerDesign);
      return this.getVacationBackgroundImage(
        this.partnerCode,
        partnerDesignInfo,
        this.str.vacation,
      );
    },
    validationRules() {
      return this.settings.calendar?.vacation || {};
    },
    boundsEurope() {
      // europe
      const { maps } = this.googleApis;
      LogService.log('maps', maps);
      if (!maps) return undefined;
      const southWest = new maps.LatLng(32.880155, -14.177284);
      const northEast = new maps.LatLng(71.594625, 45.852008);
      return new maps.LatLngBounds(southWest, northEast);
    },
    errorTextFalseCountry() {
      // get country codes of allowed countries, map them to country names in correct languages and
      // join to a list
      LogService.log('Vacation errorTextFalseCountry, insuranceCountries', this.insuranceCountries);
      const regionNames = new Intl.DisplayNames([this.lang], { type: 'region' });
      LogService.log('Vacation errorTextFalseCountry, regionNames', regionNames);
      const insuranceCountriesList = this.insuranceCountries.map((x) => regionNames.of(x))
        .sort().join(', ');
      const selectedCountry = regionNames.of(this.formData.location.country_code);
      LogService.log('Vacation errorTextFalseCountry, popup text', this.str.vacation.error_wrong_country, selectedCountry,
        insuranceCountriesList);
      return this.format(this.str.vacation.error_wrong_country, selectedCountry,
        insuranceCountriesList);
    },
    errors() {
      const emptyLocation = Object.entries(this.formData.location || {})
        .filter(([key]) => key !== 'country')
        .every(([, val]) => !val);
      return this.invalidCountry && emptyLocation
        ? [this.str.vacation.error_location] : [];
    },
    onlyForm() {
      return this.$route.query.iframe === '2';
    },
    offerText() {
      return this.str.vacation.offer_text;
    },
    vacationVer2() {
      return process.env.VUE_APP_VACATION_SURVEY === 'true';
    },
  },
  async created() {
    const redirect = this.checkIfRedirectToEnglishNecessary();
    if (!redirect) {
      await this.init();
      await this.setBusinessRulesDownloaded();
    }
  },
  methods: {
    async init() {
      this.vStemp = getVStemp();
      LogService.log('default value', new Date());
      const loadingId = nanoid();
      await LogService.log('creating screen, loadingId', loadingId);
      await this.$store.commit('SET_LOADING', loadingId);
      await Promise.all([
        this.getWordPressStrings(
          [
            wpSection.VACATION,
            wpSection.COMMON_APP,
            wpSection.SECTIONS,
            wpSection.PARTNER_DESIGN,
          ],
        ),
        this.$store.dispatch(
          'settings/getProductSettings',
          { site: 'vacation', partnerCode: this.partnerCode },
        ),
      ]);
      const partnerDesignInfo = this.getPartnerDesign(this.partnerCode, this.str.partnerDesign);
      this.str.vacation = this.updateVacationString(
        this.partnerCode,
        this.str.vacation,
        partnerDesignInfo,
      );
      await this.$store.commit('DEL_LOADING', loadingId);
    },
    updateAddress(address) {
      LogService.log('Vacation updateAddress', address);
      this.formData.location = address;
    },
    async onNextClick() {
      this.$refs.location.$refs.places.$v.$touch();
      if (this.$refs.location.$refs.places.$v.$error) {
        return;
      }
      await this.postProductAndFind(this.businessType);
      if (this.vacationVer2) {
        await this.saveSurveyApiCall();
      }

      this.redirect();
    },
    redirect() {
      this.$store.commit('RESET_LOADING');
      LogService.log('redirecting to buy-vacation');
      this.$router.push({ query: null, name: 'buy-vacation', params: { ...this.$route.params } });
    },
  },
  mounted() {
    this.resize();
    this.$root.$on('resize', this.resize);
  },
  destroyed() {
    this.$root.$off('resize', this.resize);
  },
};
</script>

<style lang="scss">
  .wet-section-vacation {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background:
        radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,.5) 70%, rgba(0,0,0,.8) 100%);
    }

    &>.container {
      position: relative;
    }
  }

  .wet-section-box-rounded {
    border-radius: var(--border-radius);
    background-color: #3B384D;
    padding: 1.5rem;
  }
</style>
